import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import DealEndPoints from '../http/endpoints/dealEndPoints';

const Sidebar = ({activeTab}) => {
    const location = useLocation();
    const shouldHideSidebarOnPage = location.pathname.startsWith('/deal/');
    const [user, setUser] = useState({});

    async function init() {
        await Promise.all([getDealsList()]);
    }

    useEffect(() => {
        init();
    }, []);

    async function getDealsList() {
        const token = localStorage.getItem('token');
        const user = await jwtDecode(token);
        setUser(user);
    }

    const handleTabClick = (tab) => {
        activeTab = tab;
    };

    return (
        <div
            className={`${
                shouldHideSidebarOnPage ? 'hidden' : ''
            }`}
        >
            <div style={{display : 'flex', width : '100%', marginTop : '15px'}}>
                <div>
                    <Link
                        to="/"
                        onClick={() => handleTabClick('Букеты')}
                        style={{
                            fontSize : '25px',
                            marginRight : '15px',
                            cursor : 'pointer',
                            borderBottom : activeTab === 'Букеты' ? '2px solid #000' : '2px solid #ccc',
                            fontWeight : activeTab === 'Букеты' ? 'bold' : 'normal',
                            padding : '10px',
                            color : activeTab === 'Букеты' ? '#000' : '#ccc',
                        }}
                    >
                        Букеты
                    </Link>
                    <Link
                        to="/manager-deals"
                        onClick={() => handleTabClick('Мои заказы')}
                        style={{
                            fontSize : '25px',
                            marginRight : '15px',
                            cursor : 'pointer',
                            borderBottom : activeTab === 'Мои заказы' ? '2px solid #000' : '2px solid #ccc',
                            fontWeight : activeTab === 'Мои заказы' ? 'bold' : 'normal',
                            padding : '10px',
                            color : activeTab === 'Мои заказы' ? '#000' : '#ccc',
                        }}
                    >
                        Мои заказы
                    </Link>
                </div>

                {user.role === 2 && (
                    <div>
                        <Link
                            to="/deals"
                            onClick={() => handleTabClick('Заказы')}
                            style={{
                                fontSize : '25px',
                                cursor : 'pointer',
                                marginRight : '15px',
                                borderBottom : activeTab === 'Заказы' ? '2px solid #000' : '2px solid #ccc',
                                fontWeight : activeTab === 'Заказы' ? 'bold' : 'normal',
                                padding : '10px',
                                color : activeTab === 'Заказы' ? '#000' : '#ccc',
                            }}
                        >
                            Все заказы
                        </Link>
                        <Link
                            to="/managers"
                            onClick={() => handleTabClick('Менеджеры')}
                            style={{
                                fontSize : '25px',
                                cursor : 'pointer',
                                marginRight : '15px',
                                borderBottom : activeTab === 'Менеджеры' ? '2px solid #000' : '2px solid #ccc',
                                fontWeight : activeTab === 'Менеджеры' ? 'bold' : 'normal',
                                padding : '10px',
                                color : activeTab === 'Менеджеры' ? '#000' : '#ccc',
                            }}
                        >
                            Менеджеры
                        </Link>

                        <Link
                            to="/managers-conversion"
                            onClick={() => handleTabClick('Конверсия')}
                            style={{
                                fontSize : '25px',
                                cursor : 'pointer',
                                borderBottom : activeTab === 'Конверсия' ? '2px solid #000' : '2px solid #ccc',
                                fontWeight : activeTab === 'Конверсия' ? 'bold' : 'normal',
                                padding : '10px',
                                color : activeTab === 'Конверсия' ? '#000' : '#ccc',
                            }}
                        >
                            Конверсия
                        </Link>
                    </div>

                )}
            </div>
        </div>
    );
};

export default Sidebar;
