import React, {useEffect, useState} from 'react';
import ProductEndPoints from '../http/endpoints/productEndPoints';
import DealEndPoints from '../http/endpoints/dealEndPoints';
import {Link} from 'react-router-dom';
import Sidebar from '../components/SideBar';

const ManagersConversion = () => {
    const [managers, setManagers] = useState([]);

    async function getConversion() {
        try {
            const response = await DealEndPoints.getConversion();
            setManagers(response.data);
        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    }

    useEffect(() => {
        getConversion();
    }, []);

    return (
        <div className="mx-6 w-full">
            <Sidebar activeTab={'Конверсия'}/>
            <div className="overflow-x-auto mt-14">
                <table className="w-full">
                    <thead>
                    <tr>
                        <th className="w-1/4 px-6 py-3 bg-gray-400 text-center text-l leading-4 text-black-500 tracking-wider">
                            Имя менеджера
                        </th>
                        <th className="w-1/4 px-6 py-3 bg-gray-400 text-center text-l leading-4 text-black-500 tracking-wider">
                            Сумма продаж
                        </th>
                        <th className="w-1/4 px-6 py-3 bg-gray-400 text-center text-l leading-4 text-black-500 tracking-wider">
                            Количество продаж
                        </th>
                        <th className="w-1/4 px-6 py-3 bg-gray-400 text-center text-l leading-4 text-black-500 tracking-wider">
                            Конверсия (%)
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {managers.map((manager, index) => (
                        <tr key={index}>
                            <td className="w-1/4 px-6 py-4 whitespace-no-wrap text-center">
                                {manager.userName}
                            </td>
                            <td className="w-1/4 px-6 py-4 whitespace-no-wrap text-center">
                                {manager.totalProductCost !== null
                                    ? manager.totalProductCost.toLocaleString('ru-RU')
                                    : 0} тг.
                            </td>
                            <td className="w-1/4 px-6 py-4 whitespace-no-wrap text-center">
                                {manager.count}
                            </td>
                            <td className="w-1/4 px-6 py-4 whitespace-no-wrap text-center">
                                {Number(manager.conversion).toFixed(2)}%
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManagersConversion;
