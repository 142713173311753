import React, {useEffect, useState} from 'react';
import Filter from '../components/Filter';
import Window from '../components/Window';
import ProductEndPoints from '../http/endpoints/productEndPoints';
import {jwtDecode} from 'jwt-decode';
import Sidebar from '../components/SideBar';
import {ThreeCircles} from 'react-loader-spinner';
import AddProductModal from '../components/AddProductModal';


const Main = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAddProductOpen, setIsModalAddProductOpen] = useState(false);
    const [size, setSize] = useState(10);
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterParams, setFilterParams] = useState({
        'selectedCategory' : 'Букеты',
        'productName' : '',
        'minPrice' : '',
        'maxPrice' : '',
        'selectedBrand' : ''
    });
    const [user, setUser] = useState({});
    const [hideFilter, setHideFilter] = useState(true);
    const [height, setHeight] = useState('67vh');
    const handleRowClick = (product) => {
        if (selectedProducts.some((p) => p.id === product.id)) {
            setSelectedProducts((prevSelected) =>
                prevSelected.filter((p) => p.id !== product.id)
            );
        } else {
            setSelectedProducts((prevSelected) => [...prevSelected, product]);
        }
    };

    const handleCheckboxChange = (product) => {
        if (selectedProducts.some((selected) => selected.id === product.id)) {
            setSelectedProducts(
                selectedProducts.filter((selected) => selected.id !== product.id)
            );
        } else {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleApplyFilter = async (params) => {
        setIsLoading(true);
        setFilterParams(params);
        setIsLoading(false);

    };
    const openFullscreen = (photoUrl) => {
        setFullscreenImage(photoUrl);
    };
    const closeFullscreen = () => {
        setFullscreenImage(null);
    };

    function formatCost(cost) {
        if (cost === null) {
            return '0 тг.';
        }
        return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' тг.';
    }

    const openModal = async () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProducts([]);
    };
    const openModalAddProduct = async () => {
        setIsModalAddProductOpen(true);
    };
    const closeModalAddProduct = () => {
        setIsModalAddProductOpen(false);
    };
    const handleToggleFilter = () => {
        setHideFilter(!hideFilter);
        hideFilter ? setHeight('78vh') : setHeight('66vh');
    };

    async function init() {
        setIsLoading(true);
        await Promise.all([updateAll(), getProducts()]);
        await setIsLoading(false);
    }

    useEffect(() => {
        init();
    }, [size, currentPage, filterParams]);

    async function updateAll() {
        setIsLoading(true);
        const res = await ProductEndPoints.updateAllProducts();
        setIsLoading(false);

    }

    async function getProducts() {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        const user = await jwtDecode(token);
        setUser(user);
        const products = await ProductEndPoints.getProducts(size, currentPage, filterParams);
        setPage(Math.ceil(products.data.count / size));
        await setData(products.data.rows);
        await setIsLoading(false);
    }

    return (
        <div className={'w-full mx-6'}>
            <Sidebar activeTab={'Букеты'}/>
            {hideFilter && (<Filter onApplyFilter={handleApplyFilter}/>)}

            <div className="flex justify-between items-end w-full my-2">
                <div>
                    <button
                        onClick={handleToggleFilter}
                        className={'font-medium text-blue-600 py-2'}>
                        {hideFilter ? 'Скрыть фильтр' : 'Показать фильтр'}
                    </button>
                    <h1 className={'text-xm text-gray-500'}>Выбрано букетов: {selectedProducts.length}</h1>
                </div>

                <div>
                    <button
                        disabled={selectedProducts.length === 0}
                        className={`font-medium text-white py-2 px-4 rounded float-right ${
                            selectedProducts.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-slate-950'
                        }`}
                        onClick={openModal}
                    >
                        Сформировать заказ
                    </button>
                    {user.role === 2 && (
                        <button
                            className={`font-medium text-white py-2 px-4 rounded float-right bg-slate-950 mr-5`}
                            onClick={openModalAddProduct}
                        >
                            Добавить товар
                        </button>)}
                </div>
            </div>

            <div className="overflow-auto" style={{height : height}}>
                {isLoading ?
                    <div style={{
                        display : 'flex',
                        justifyContent : 'center',
                        alignItems : 'center',
                        height : '50vh'
                    }}>
                        <ThreeCircles color="#0fa493"/>
                    </div> :
                    <table className="w-full  divide-gray-200">
                        <thead>
                        <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Выбор
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Фото
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Название
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Состав
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Бренд/Стоимость
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            </th>
                        </tr>
                        </thead>


                        <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((product) => (
                            <tr
                                key={product.id}
                                onClick={() => handleRowClick(product)}
                                style={{display : product.balance === 0 ? 'none' : 'table-row'}}
                                className={`cursor-pointer hover:bg-gray-100 ${
                                    selectedProducts.some((p) => p.id === product.id)
                                        ? 'bg-gray-100'
                                        : 'white'
                                }`}
                            >
                                <td className="px-6 py-2 whitespace-no-wrap">
                                    <div className="flex items-center h-full">
                                        <input
                                            type="checkbox"
                                            checked={selectedProducts.includes(product)}
                                            onChange={() => handleCheckboxChange(product)}
                                            className="form-checkbox h-5 w-5"
                                        />
                                    </div>
                                </td>
                                <td className="px-6 py-2 whitespace-no-wrap">
                                    <img
                                        src={product.photo}
                                        alt={product.name}
                                        className="h-32 w-32 cursor-pointer rounded-xl"
                                        onClick={() => openFullscreen(product.photo)}
                                    />
                                </td>
                                <td className="px-6 py-2 text-xs whitespace-no-wrap">{product.name}</td>
                                <td className="px-6 py-2 text-xs whitespace-no-wrap">
                                    {Array.isArray(product.composition?.item) ? (
                                        product.composition.item.map((item, index) => (
                                            <div key={index}>{`${item.name} - ${item.quantity} шт.`}</div>
                                        ))
                                    ) : (
                                        'Нет данных'
                                    )}
                                </td>
                                <td className="px-6 py-2 text-xs whitespace-no-wrap">

                                    {Array.isArray(product.projects) ? (
                                        product.projects.map((project) => (
                                            <div key={project.id}>{project.name}/{formatCost(project.price)}</div>
                                        ))
                                    ) : (
                                        'Нет данных'
                                    )}

                                </td>
                                {user.role === 2 ? <td className="px-6 py-2 text-blue-500 whitespace-nowrap">
                                    <a href={`/product/${product.id}`}>Изменить</a>
                                </td> : <td></td>}

                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
            </div>

            <div className="flex justify-between items-center  mt-6 space-x-2">
                <div className="flex space-x-2">
                    <label className="mr-2 text-xm">Размер:</label>
                    <select
                        onChange={(e) => setSize(Number(e.target.value))}
                        value={size}
                        className="border rounded xm border-gray-300 px-3 py-1"
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                </div>

                <div className="flex space-x-2">
                    <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        className={`py-0.5 px-1 border border-gray-300 rounded text-xm ${
                            currentPage < 1 ? 'cursor-not-allowed' : 'hover:bg-gray-200'
                        }`}
                        disabled={currentPage < 1}
                    >
                        {'<'}
                    </button>
                    <span className="px-2 py-1 text-xm border border-gray-300 rounded">{currentPage + 1}</span>
                    <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        className={`py-0.5 px-1 border border-gray-300 rounded text-xm ${
                            currentPage === page ? 'cursor-not-allowed' : 'hover:bg-gray-200'
                        }`}
                        disabled={currentPage === page}
                    >
                        {'>'}
                    </button>
                </div>

                <span className={'text-xm'}>{`Страница ${currentPage + 1} из ${page + 1}`}</span>
            </div>

            {fullscreenImage && (
                <div
                    className="fixed top-0 left-0 w-screen h-screen bg-black flex justify-center items-center"
                    onClick={closeFullscreen}
                >
                    <img
                        src={fullscreenImage}
                        alt="Полноэкранное изображение"
                        className="max-h-screen max-w-screen"
                    />
                </div>
            )}
            <Window
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                selectedProducts={selectedProducts}
            />

            <AddProductModal
                isModalOpen={isModalAddProductOpen}
                closeModal={closeModalAddProduct}
            />


        </div>
    );
};

export default Main;
