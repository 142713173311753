import {instance} from '../instance';

class ClientEndPointsList {
    async checkClient(phone) {
        return await instance.get(`/client/checkClient/${phone}`);
    }

    async getClient(phone) {
        return await instance.get(`/client/${phone}`);
    }

    async createClient(body) {
        return await instance.post(`/client`, body);
    }
}

const ClientEndPoints = new ClientEndPointsList();
export default ClientEndPoints;
