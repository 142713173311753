import React from 'react';
import {useRoutes} from 'react-router';
import {Helmet} from 'react-helmet';
import Main from './pages/Main';
import Deals from './pages/Deals';
import Product from './pages/Product';
import ManagersConversion from './pages/ManagersConversion';
import Managers from './pages/Managers';
import Auth from './pages/Auth';
import DealInfo from './pages/DealInfo';
import DealsForManager from './pages/DealsForManager';
import {useLocation} from 'react-router-dom';

export const RouteList = [
    {
        path : '/',
        title : 'Главная',
        element : <Main/>,
    },
    {
        path : '/deals',
        title : 'Заказы',
        element : <Deals/>,
    },
    {
        path : '/manager-deals',
        title : 'Заказы менеджера',
        element : <DealsForManager/>,
    },
    {
        path : '/deal-info/:id',
        title : 'Инфо о заказе',
        element : <DealInfo/>,
    },
    {
        path : '/product/:id',
        title : 'Букет',
        element : <Product/>,
    },
    {
        path : '/managers',
        title : 'Mенеджерs',
        element : <Managers/>,
    },
    {
        path : '/managers-conversion',
        title : 'Конверсия менеджеров',
        element : <ManagersConversion/>,
    },
    {
        path : '/auth',
        title : 'Авторизация',
        element : <Auth/>,
    },
];

const Router = () => {
    const routeElement = useRoutes(RouteList);
    const location = useLocation();
    const firstSegment = location.pathname.split('/')[1];
    const currentRoute = RouteList.find(route => route.path.split('/')[1] === firstSegment);
    return (
        <>
            <Helmet>
                <title>{currentRoute?.title || 'Заголовок по умолчанию'}</title>
            </Helmet>
            {routeElement}
        </>
    );
};

export default Router;
