import {instance} from '../instance';

class DealEndPointsList {
    async createDeal(body) {
        return await instance.post('/deal', body);
    }

    async getDeal(id) {
        return await instance.get(`/deal/${id}`);
    }

    async getDealsList(size, page) {
        return await instance.get(`/deal?size=${size}&page=${page}`);
    }

    async getManagerDealsList(id, size, page) {
        return await instance.get(`/deal/manager?id=${id}&size=${size}&page=${page}`);
    }

    async updateDeal(body) {
        return await instance.put(`/deal`, body);
    }

    async updateDealInfo(body) {
        return await instance.put(`/deal/dealInfo`, body);
    }

    async getConversion() {
        return await instance.get(`/deal/conversion`);
    }
}

const DealEndPoints = new DealEndPointsList();
export default DealEndPoints;
