import InputMask from 'react-input-mask';
import Modal from 'react-modal';
import React, {useEffect, useState} from 'react';
import ClientEndPoints from '../http/endpoints/clientEndPoints';
import DealEndPoints from '../http/endpoints/dealEndPoints';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import _ from 'lodash';
import ProductEndPoints from '../http/endpoints/productEndPoints';


const Window = ({isModalOpen, closeModal, selectedProducts}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [createClient, setCreateClient] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [linkIsOpen, setLinkIsOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [link, setLink] = useState('');
    const [client, setClient] = useState({});
    const [sP, setsP] = useState(selectedProducts);

    const [newClientName, setNewClientName] = useState('');

    function formatCost(cost) {
        if (cost === null) {
            return '0 тг.';
        }
        return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' тг.';
    }

    const findClient = async (phone) => {
        setPhoneNumber(phone);
        const cleanedPhoneNumber = phone.replace(/\D/g, '');
        if (cleanedPhoneNumber.length === 11) {
            setShowCreate(false);
            const response = await ClientEndPoints.checkClient(cleanedPhoneNumber);
            console.log(response);
            if (response.data.id) {
                console.log(response);
                setShowInfo(true);
                setIsEnabled(true);
                setClient(response.data);
            } else {
                setShowCreate(true);
                setClient({});
            }
        } else {
            setShowInfo(false);
            setIsEnabled(false);
            setLinkIsOpen(false);
        }
    };

    const createDeal = async () => {
        const storedToken = localStorage.getItem('token');
        const user = await jwtDecode(storedToken);
        const date = new Date();
        const productIds = selectedProducts.map(item => item.id);
        const deal = {
            date : date,
            products : productIds,
            status : 'notPaid',
            clientId : client.id,
            userId : user.id
        };
        const res = await DealEndPoints.createDeal(deal);
        setLinkIsOpen(true);
        setLink(`https://zakazbuketovkp.kz/deal/${res.data.id}`);
    };

    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(link);
            setCopied(true);
            window.location.reload();
        } catch (error) {
            console.error('Ошибка копирования в буфер обмена: ', error);
        }
    };

    const showCreateClient = () => {
        setCreateClient(true);
    };

    const createClientFunc = async () => {
        const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
        const newClient = {
            phone : `+${cleanedPhoneNumber}`,
            fullName : newClientName,
            bonus : 0
        };
        await ClientEndPoints.createClient(newClient);
        setCreateClient(false);
    };

    const modalStyles = {
        content : {
            top : '50%',
            left : '50%',
            right : 'auto',
            bottom : 'auto',
            transform : 'translate(-50%, -50%)',
            padding : '20px',
            boxShadow : '0 2px 10px rgba(0, 0, 0, 0.2)',
            borderRadius : '8px',
            maxWidth : '600px',
            width : '100%',
        },
        overlay : {
            backgroundColor : 'rgba(0, 0, 0, 0.5)',
            zIndex : '1000',
            display : 'flex',
            justifyContent : 'center',
            alignItems : 'center',
        },
    };

    const handleRadioChange = (productId, projectId) => {
        const updatedProducts = _.map(selectedProducts, async product => {
            const updatedProjects = _.map(product.projects, project => ({
                ...project,
                selected : project.id === projectId
            }));

            const selectedProject = _.find(updatedProjects, {id : projectId});

            if (selectedProject) { // Проверяем, был ли найден выбранный проект
                try {
                    const res = await ProductEndPoints.updateProduct({productId, selectedProject});
                } catch (error) {
                    console.error('Ошибка при обновлении цены продукта:', error);
                }

                return {
                    ...product,
                    brand : selectedProject.name,
                    price : selectedProject.price,
                    projects : updatedProjects
                };
            } else {
                return product; // Возвращаем неизмененный продукт, если проект не найден
            }
        });

        setsP(updatedProducts);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            style={modalStyles}
            contentLabel="Модальное окно"
            ariaHideApp={false}
            onRequestClose={closeModal}
        >
            <button
                onClick={closeModal}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
                &#x2716;
            </button>
            <div className="p-4">
                <h2 className="text-xl font-bold mb-2">Отправка клиенту</h2>

                <div style={{maxHeight : '40vh', overflowY : 'auto'}}>
                    <h3 className="text-xm  mb-2">Выбранные букеты:</h3>
                    {selectedProducts.map((product, index) => (
                        <div key={product.id}>
                            <p className="text-xm font-bold mb-2">{index + 1}.{product.name}</p>
                            {product.projects.map((project, projectIndex) => (
                                <div key={project.id}>
                                    <input
                                        type="radio"
                                        id={`radio-${product.id}-${project.id}`} // Уникальный идентификатор для радио-батона
                                        name={`selectedProduct-${product.id}`} // Одинаковое имя для радио-батонов в рамках одного продукта
                                        value={project.id}
                                        onChange={() => handleRadioChange(product.id, project.id)}
                                    />
                                    <label htmlFor={`radio-${product.id}-${project.id}`} className="ml-2">
                                        {project.name} - {formatCost(project.price)}
                                    </label>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

                <label>Телефон</label>
                <InputMask
                    value={phoneNumber}
                    onChange={(e) => findClient(e.target.value)}
                    mask="+9(999)999-99-99"
                    maskChar=" "
                    className="w-full py-2 px-3 mb-2 rounded-md border border-gray-300 focus:ring focus:ring-blue-500 focus:outline-none focus:border-blue-500"
                    placeholder="+7(___)___-__-__"
                />

                {showInfo && (
                    <div>
                        <label>Имя</label>
                        <InputMask
                            className="w-full py-2 px-3 mb-2 rounded-md border border-gray-300 focus:ring focus:ring-blue-500 focus:outline-none focus:border-blue-500"
                            value={`${client.fullName}`}
                            readOnly={true}
                        />
                        <label>Бонусы</label>
                        <InputMask
                            className="w-full py-2 px-3 mb-2 rounded-md border border-gray-300 focus:ring focus:ring-blue-500 focus:outline-none focus:border-blue-500"
                            value={`${client.bonus} тг.`}
                            readOnly={true}
                        />
                        {/*<label>Действуют до: </label>*/}
                        {/*<label className="text-blue-500 font-bold">12.02.2023 </label>*/}
                    </div>
                )}

                {showCreate && (
                    <div className={'flex'}>
                        <h1>Клиент не найден!</h1>
                        <button
                            className={'text-blue-500 font-bold text-l ml-2'}
                            onClick={showCreateClient}
                        >
                            Добавить
                        </button>
                    </div>
                )}

                {createClient && (
                    <div>
                        <label>Имя</label>
                        <InputMask
                            className="w-full py-2 px-3 mb-2 rounded-md border border-gray-300 focus:ring focus:ring-blue-500 focus:outline-none focus:border-blue-500"
                            onChange={(e) => setNewClientName(e.target.value)}
                        />
                        <button
                            className={`w-full text-xl font-bold 'bg-slate-950 text-slate-50'  py-4 px-4 mt-4 rounded border-2`}
                            onClick={createClientFunc}
                        >
                            Добавить клиента
                        </button>
                    </div>
                )}


                <button
                    className={`w-full text-xl font-bold  py-4 px-4 mt-4 rounded border-2 ${
                        isEnabled
                            ? 'bg-slate-950 text-slate-50'
                            : 'bg-gray-300 text-gray-500 disabled cursor-not-allowed'
                    }`}
                    disabled={!isEnabled}
                    onClick={createDeal}
                >
                    Сформировать КП
                </button>

                {linkIsOpen && (
                    <div className="mt-4 flex justify-between">
            <span className="mt-4 mr-2" role="img" aria-label="Ссылка">
              🔗
            </span>
                        <p className="mt-4">
                            <a href={link} className="text-blue-500 text-xl hover:underline">
                                {link}
                            </a>
                        </p>

                        <button
                            onClick={copyTextToClipboard}
                            className="bg-green text-white py-2 px-4 ml-8 rounded-md mt-4"
                        >
                            {copied ? 'Скопировано!' : 'Скопировать'}
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default Window;
