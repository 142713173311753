import React, {useEffect, useState} from 'react';
import Router from './Router';
import Sidebar from './components/SideBar';
import Auth from './pages/Auth';

function App() {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState({});

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const user = localStorage.getItem('user');
        if (storedToken) {
            setToken(storedToken);
        }
        if (user) {
            setUser(user);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setToken(null);
    };


    return (
        <div>
            {token ? (
                <div>
                    <div className={'app flex'}>
                        <Router className="flex-1 overflow-x-hidden overflow-y-auto p-4"/>
                        {window.location.pathname.startsWith('/deal/') ? null : (
                            <button
                                onClick={handleLogout}
                                className="absolute top-0 right-0 p-2 mb-8 mt-2 mx-4 bg-red-500 text-white rounded"
                            >
                                {user.username} Выйти
                            </button>
                        )}
                    </div>

                </div>
            ) : (
                <Auth onLogin={setToken}/>
            )}
        </div>


    );
}

export default App;
