import axios from 'axios';

const instance = axios.create({
    baseURL : 'https://zakazbuketovkp.kz/api',
    // baseURL : 'http://localhost:8080/api',
    withCredentials : false,
    headers : {},
});

export {instance};
