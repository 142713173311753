import React, {useEffect, useState} from 'react';
import ProductEndPoints from '../http/endpoints/productEndPoints';

const Filter = ({onApplyFilter}) => {
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Букеты');
    const [productName, setProductName] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');

    const handleApplyFilter = () => {
        const filterParams = {
            selectedCategory,
            productName,
            minPrice,
            maxPrice,
            selectedBrand,
        };
        // Вызываем функцию обратного вызова с параметрами фильтрации
        onApplyFilter(filterParams);
    };

    async function init() {
        await Promise.all([getBrands(), getCategories()]);
    }

    useEffect(() => {
        init();
    }, []);

    async function getBrands() {
        const brands = await ProductEndPoints.getBrands();
        setBrands(brands.data);
    }

    async function getCategories() {
        const categories = await ProductEndPoints.getCategories();
        setCategories(categories.data);

    }

    return (
        <div className="my-4">
            <div className="flex flex-wrap items-end -mx-2">
                <div className="w-1/4 px-2 mb-2">
                    <label htmlFor="bouquet" className="block font-medium">
                        Категория
                    </label>
                    <select
                        id="bouquet"
                        className="w-full border p-2 rounded"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        {categories.map((item, index) => (
                            <option key={index} value={item.section}>
                                {item.section}
                            </option>
                        ))}

                    </select>
                </div>
                <div className="w-1/4 px-2 mb-2">
                    <label htmlFor="productName" className="block font-medium">
                        Название
                    </label>
                    <input
                        type="text"
                        id="productName"
                        className="w-full border p-2 rounded"
                        placeholder="Название букета"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                    />
                </div>
                <div className="w-1/5 px-2 mb-2">
                    <label className="block font-medium">Стоимость</label>
                    <div className="flex">
                        <input
                            type="number"
                            className="w-1/2 border p-2 rounded mr-2"
                            placeholder="Мин"
                            value={minPrice}
                            onChange={(e) => setMinPrice(e.target.value)}
                        />
                        <input
                            type="number"
                            className="w-1/2 border p-2 rounded"
                            placeholder="Макс"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(e.target.value)}
                        />
                    </div>
                </div>
                <div className="w-1/5 px-2 mb-2">
                    <label htmlFor="brand" className="block font-medium">
                        Бренд
                    </label>
                    <select
                        id="brand"
                        className="w-full border p-2 rounded"
                        value={selectedBrand}
                        onChange={(e) => setSelectedBrand(e.target.value)}
                    >
                        <option value="">Не выбрано</option>
                        {brands.map((item, index) => (
                            <option key={index} value={item.brand}>
                                {item.brand || 'Без бренда'}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-2 mb-2">
                    <button
                        className="bg-green text-white px-4 py-2 rounded"
                        onClick={handleApplyFilter}
                    >
                        Применить
                    </button>
                </div>
            </div>

        </div>
    );
};

export default Filter;
