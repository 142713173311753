import {instance} from '../instance';

class ProductEndPointsList {

    async updateAllProducts() {
        return await instance.put(`/product/updateAll`);
    }

    async getProducts(size, page, filterParams) {
        return await instance.post(`/product?size=${size}&page=${page}`, filterParams);
    }

    async findProduct(artNumber) {
        return await instance.get(`/product?artNumber=${artNumber}`);
    }

    async createProduct(body) {
        return await instance.post(`/product/create`, body);
    }

    async getBrands() {
        return await instance.get(`/product/brands`);
    }

    async getCategories() {
        return await instance.get(`/product/category`);
    }

    async getProduct(id) {
        return await instance.get(`/product/${id}`);
    }

    async updateProduct(body) {
        return await instance.post(`/product/update`, body);
    }

    async deletePhoto(body) {
        return await instance.post(`/product/delete-photo`, body);
    }
}

const ProductEndPoints = new ProductEndPointsList();
export default ProductEndPoints;
